<template>
<div class="carosel-container">
    <carousel  :rtl='true' :per-page="4" :autoplay='true' :loop='true' :speed='900' :per-page-custom='[[320, 1], [1000, 4]]' :autoplay-timeout="3000">
        <slide v-for="(coin , index) in cryptoList" :key="index" @click="goto($coin2Snake[coin.relatedCoin])" class="main-slide">
            <div class="slide">
                <div class="information">
                    <span class="crypto-name">
                        {{$coinLabel[coin.relatedCoin]}}
                    </span>
                    <span class="crypto-price">
                        {{$toLocal(coin.lastPrice,$decimal['TOMAN'])}}
                    </span>
                    <span class="crypto-change" :class="{'negetive-change':coin.last24HourChange < 0 ,'positive-change':coin.last24HourChange > 0 }">
                        <span v-if="coin.last24HourChange > 0">+</span> {{$toLocal(Math.abs(coin.last24HourChange),1)}} %
                    </span>
                </div>
                <div class="image">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" class="image-slide" alt="">
                </div>
            </div>
        </slide>
    </carousel>
</div>
</template>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
export default {
    name: 'slider',
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            cryptoList: [],
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +

                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +

                '&market_type=TETHER_TOMAN',
        }
    },
    methods: {
        async setData() {
            this.state.loading = true
            const res = await this.$axios.get('/trades/market-info?' + this.TomanCoin, {
                params: {
                    weeklyDataIncluded: true,
                }
            })
            this.cryptoList = res.content
        },
        goto(e) {
            this.$router.push('/profile/trade/' + 'TOMAN' + `/${e}`)
        },
    },
    mounted() {
        this.setData()
    }
}
</script>

<style scoped>
.slide {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(5px);
    border-radius: 3px;
    /* width: 277px; */
    height: 116px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 0 20px; */
}
.main-slide {
    padding: 0 10px;
}

@supports not (backdrop-filter: blur(10px)) {
    .slide {
        background: #252833 !important;
    }
}

.information,
.image {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-slide {
    width: 70px;
    height: 70px;
}

.crypto-name {
    color: #FFFFFF;
    font-weight: normal;
    font-size: 18px;
}

.crypto-price {
    font-weight: normal;
    font-size: 18px;
    color: #80CC52;
}

.crypto-change {
    font-weight: normal;
    font-size: 14px;
    direction: ltr;
}

.negetive-change {
    color: rgb(247, 131, 131);
}

.positive-change {
    color: #80CC52;
}
</style><style>
.VueCarousel-inner {
    /* flex-basis: 325px !important; */
}

.VueCarousel {
    direction: revert !important;
    width: 90%;
    /* max-width: 1400px; */
    margin: 0 auto;
}

.VueCarousel-pagination {
    height: 10px !important;
}

.VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
    background-color: black !important;
    margin-top: 0 !important;
}

.VueCarousel-dot--active {
    background-color: gainsboro !important;
}

.VueCarousel-dot-container {
    margin-top: 10px !important;
    display: none 1 !important;
}

.VueCarousel-wrapper {
    /* background: linear-gradient(180deg, rgba(39, 42, 51, 0.2), #363941); */
    /* background: linear-gradient(180deg, rgba(29, 32, 41, 0.2), #3f4147); */
}
</style>
