<template>
<div class="navbar">
    <button class="ham" @click.stop="toggleHamburger">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    </button>
    <div class="nav-container">
        <a href="/home-page" @click.prevent="$router.push('/')" class="bamboo_link">
            <div class="title">
                <img class="bamboo-img" src="@/assets/bamboo.png" alt="">
                <span class="bamboo-title"> صرافی </span> <span class="bamboo-name"> بامبو </span>
            </div>
        </a>
        <div class="links">
            <a v-for="links , index in links" :key="index" :href="links.path" @click.prevent="$router.push(links.path)">
                <span>
                    {{links.title}}
                </span>
            </a>
            <span class="guid">
                راهنما
                <img class="guid-v" src="@/assets/guid-v.svg" alt="">
            </span>
            <div class="guid-options">
                <!-- <a href="/home#test-1235">
                            <p>
                                بازار ها
                            </p>
                        </a> -->
                <a v-for="guideOptions , index in guideOptions" :key="index" :href="guideOptions.path" @click.prevent="$router.push(guideOptions.path)">
                    <p>
                        {{guideOptions.title}}
                    </p>
                </a>
            </div>
        </div>
        <div class="login-sub-mobile" v-if="state.token" >
            <span class="profile-link" @click.prevent="profileDrop = !profileDrop">
                <inline-svg :src="require('@/assets/icon.svg')" :width="50" />
                <span class="user_family" v-if="state.userInfo.lastName!==''">
                    {{state.userInfo.lastName}}
                </span>
                <inline-svg :src="require('@/assets/guid-v.svg')" />
            </span>
            <div class="profile_drop" v-if="profileDrop" >
                <div class="profile_drop_item" @click="$router.push('/profile')">
                    <inline-svg :src="require('@/assets/dashboard.svg')" />
                    <span>
                        داشبورد
                    </span>
                </div>
                <div class="profile_drop_item" @click="state.exit = true">
                    <inline-svg :src="require('@/assets/exit.svg')" />
                    <span>
                        خروج
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="login-sub">
            <p class="login-p" @click="LoginModal">
                ورود
            </p>
            <button class="sub-btn" @click="SubModal">
                ثبت نام
            </button>
        </div>
    </div>

    <div class="overlay"></div>
    <nav class="sideNav" v-click-outside="onClickOutside">
        <button class="menuX" @click.stop="toggleHamburger">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <div class="mobile-nav-items">
            <div class="login-sub-mobile" v-if="state.token">
                <span class="profile-link" @click="$router.push('/profile')">
                    حساب کاربری
                    <!-- <inline-svg :src="require('@/assets/guid-v.svg')" /> -->
                </span>
            </div>
            <div class="login-sub-mobile" v-else>
                <p class="login-p" @click="LoginModal">
                    ورود
                </p>
                <button class="sub-btn" @click="SubModal">
                    ثبت نام
                </button>
            </div>
            <ul>
                <a v-for="links , index in links" :key="index" :href="links.path" @click.prevent="$router.push(links.path)">
                    <!-- @click='links.function(index)' -->
                    <span>
                        {{links.title}}
                    </span>
                </a>
                <div>
                    <span class="guid">
                        راهنما
                        <img class="guid-v" src="@/assets/guid-v.svg" alt="">
                        <!-- <inline-svg :src="require('@/assets/guid-v.svg')"></inline-svg> -->
                    </span>
                    <div class="guid-options">
                        <!-- <a href="/home#test-1235">
                            <p>
                                بازار ها
                            </p>
                        </a> -->
                        <a v-for="guideOptions , index in guideOptions" :key="index" :href="guideOptions.path" @click.prevent="$router.push(guideOptions.path)">
                            <p>
                                {{guideOptions.title}}
                            </p>
                        </a>
                    </div>
                </div>
            </ul>
        </div>
    </nav>

</div>
</template>

<script>
import Vue2ClickOutside from 'vue2-click-outside'
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'Navbar',
    components: {
        InlineSvg
    },
    data() {
        return {
            login: true,
            sub: true,
            profileDrop:false,
            links: [{
                    title: "بازار ها",
                    path: "/markets",
                    //   fun:"toMarket"
                },
                {
                    title: "صرافی آنلاین",
                    path: "/online-prices"
                },
                {
                    title: "معامله آسان",
                    path: "/basic-trade"
                },
                {
                    title: "معامله حرفه ای",
                    path: "/advanced-trade"
                }
            ],
            guideOptions: [{
                    title: "سوالات متداول",
                    path: "/faq"
                },
                {
                    title: "راهنمای استفاده",
                    path: "/how-to-use"
                },
                {
                    title: "کارمزد ها",
                    path: "fees"
                },
                {
                    title: "بلاگ بامبو",
                    path: "/blog/"
                },
                {
                    title: "درباره ما",
                    path: "/about"
                }
            ]
        }
    },
    directives: {
        clickOutside: Vue2ClickOutside.directive
    },
    methods: {
        LoginModal() {
            this.$emit('childToLogin', this.login)
            //   document.getElementById('markets').scrollIntoView()
        },
        SubModal() {
            this.$emit('childToSub', this.sub)
        },
        toggleHamburger() {
            const sideNav = document.querySelector(".sideNav")
            const overlay = document.querySelector(".overlay")
            overlay.classList.toggle("showOverlay")
            sideNav.classList.toggle("showNav")
        },
        onClickOutside() {
            const sideNav = document.querySelector(".sideNav")
            const overlay = document.querySelector(".overlay")
            overlay.classList.remove("showOverlay")
            sideNav.classList.remove("showNav")
        },
        toMarket() {
            console.log("jhjkhkjvgjgv")
        }

    }

}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.navbar {
    height: 50px;
    background: #3D49CC;
    border-bottom: 1px solid #FFFFFF;
    position: fixed;
    top: 0;
    z-index: 10;
    left: 0;
    right: 0;
    justify-content: center;

    .nav-container {
        justify-content: space-between;
        max-width: 1440px;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 0 10px;

        .title {
            column-gap: 10px;

            .bamboo-img {
                width: 50px;
                height: 50px;
            }

            .bamboo-name,
            .bamboo-title {
                font-weight: bold;
            }

            .bamboo-name {
                color: #80CC52;
            }

            .bamboo-title {
                color: #ffff;
            }
        }

        .links {
            justify-content: center;
            column-gap: 50px;
            position: relative;

            span {
                font-weight: normal;
                color: #FFFFFF;
                opacity: 0.6;
                height: 50px;
                position: relative;
            }

            span:hover {
                opacity: 1;
            }

            span:hover>.guid-v {
                transform: rotate(180deg);
            }

            .guid {
                column-gap: 10px;
            }

            .guid:hover+.guid-options {
                display: block;
            }

            .guid-options {
                position: absolute;
                background: #FFFFFF;
                box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
                border-radius: 3px;
                width: 132px;
                height: 200px;
                padding: 10px;
                font-weight: normal;
                top: 50px;
                left: -30px;
                display: none;
            }

            .guid-options:hover {
                display: block;
            }
        }
    }

    .login-sub {
        justify-content: center;
        column-gap: 10px;

        .login-p {
            font-weight: normal;
            color: #FFFFFF;
            width: 90px;
            height: 34px;
            border-radius: 3px;
            margin: 0;
            justify-content: center;
        }

        .login-p:hover {
            background: #4552E6;
        }

        .sub-btn {
            background: #FFFFFF;
            border-radius: 3px;
            border: 0;
            height: 34px;
            width: 90px;
            color: #3D49CC;
        }

        .sub-btn:hover {
            background: #80CC52;
            color: white;
        }
    }

}

a:-webkit-any-link {
    color: black;
}

.navbar,
.nav-container,
.title,
.login-sub,
.login-p,
.links,
.links>span {
    display: flex;
    align-items: center;
}

.title,
.login-p,
.links span,
.guid-options>p {
    cursor: pointer;
}

.bamboo-name,
.bamboo-title,
.login-p,
.links span,
.guid-options {
    font-size: clamp(12px, 1vw, 14px);
}

.ham {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
    background: none;

    border: none;
    cursor: pointer;
    color: white;
    display: none;

}

.menuX {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.sideNav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    /* background: white; */
    background: #3D49CC;
    width: 160px;
    height: 100vh;
    /* overflow: hidden; */
    overflow: scroll;
    color: white;
    /* hide the menu above the screen by default */
    transform: translateX(100%);
    transition: transform 0.4s ease;

}

.showNav {
    transform: translateX(0);
}

.sideNav ul {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 30px 0;
    padding-right: 0;
    row-gap: 50px;
    position: relative;

    .guid:hover+.guid-options {
        display: block;
    }

    .guid-options {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        width: 132px;
        height: 200px;
        padding: 10px;
        font-weight: normal;
        top: 332px;
        left: 10px;
        display: none;
    }

    .guid-options:hover {
        display: block;
    }
}

.mobile-nav-items {
    padding-top: 4rem;
}

.sideNav span {
    display: block;
    color: white;
    /* padding-bottom: 10px; */
    /* margin-top: 1rem; */
    /* border-bottom: 2px solid black; */
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 14px;
    width: 100%;
}

.sideNav ul li a:hover,
.sideNav ul li a:focus {
    border-bottom: 2px solid white;
}

.overlay {
    position: fixed;
    display: none;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
}

.showOverlay {
    display: block;
}

.mobile-menu-btn {
    background: transparent;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-btn-contaner {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
}

.login-sub-mobile {
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    position: relative;

    .profile_drop {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 60px;
        background: var(--background-child);
        font-size: 14px;
        color: #3D49CC;
        width: 80px;
        padding: 5px;
        border-radius: 3px;
        height: 70px;
        .profile_drop_item {
            display: flex;
            align-items: center;
            height: 30px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            cursor: pointer;
            svg {
                fill: #3D49CC;

            }

            path {
                fill: #3D49CC;
            }

        }
    }

    .login-p {
        font-weight: normal;
        color: #FFFFFF;
        width: 90px;
        height: 34px;
        border-radius: 3px;
        margin: 0;
        justify-content: center;

    }

    .login-p:hover {
        background: #4552E6;
    }

    .sub-btn {
        background: #FFFFFF;
        border-radius: 3px;
        border: 0;
        height: 34px;
        width: 90px;
        color: #3D49CC;
    }

    .sub-btn:hover {
        background: #80CC52;
        color: white;
    }
}

.profile-link {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #FFFFFF;
    font-size: clamp(12px, 1vw, 14px);
    cursor: pointer;

    svg {
        fill: white !important;
        width: 12px;
    }
}

@media only screen and (max-width:1000px) {

    .links,
    .login-sub {
        display: none;
    }

    .ham-icon,
    .ham {
        display: inline-block;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
    }

    /* .title {
        flex-direction: row-reverse;
    } */
    .nav-container {
        justify-content: center !important;
    }

    .bamboo_link {
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width:600px) {
    .user_family {
        display: none;
    }
}
</style>
